body {
    background-color: #f1f8e9;
}

.imgSlider {
    height: 80%;
}

.navbarcustcss {
    z-index: 990;
    background-color: #404e40;
}

.navbarcustcss .nav-link,
.navbarcustcss .nav-link:hover,
.navbarcustcss .nav-link:focus,
.navbarcustcss .nav-link::after {
    color: #ffffff;
}

.topbarcustcss {
    background-color: #f1f8e9 !important;
}

.custfooterbgcc {
    background-color: #dcedc8 !important;
}

.justifier {
    text-align: justify;
    text-justify: inter-word;
}

.topbar.border-bottom {
    border-bottom: 5px solid #64dd17 !important;
    background-color: #dcedc8;
}

.searchfieldbg {
    background-color: #dcedc8;
}

.boxe {
    position: relative;
    max-width: 600px;
    width: 90%;
    height: 400px;
    background: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

/* common */
.ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
}

.ribbon::before,
.ribbon::after {
    position: absolute;
    z-index: -1;
    content: "";
    display: block;
    border: 5px solid #2980b9;
}

.ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 15px 0;
    background-color: #3498db;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    color: #fff;
    font: 700 18px/1 "Lato", sans-serif;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    text-align: center;
}

/* top left*/
.ribbon-top-left {
    top: +610px;
    left: +90px;
    z-index: 10;
}

.ribbon-top-left::before,
.ribbon-top-left::after {
    border-top-color: transparent;
    border-left-color: transparent;
}

.ribbon-top-left::before {
    top: 0;
    right: 0;
}

.ribbon-top-left::after {
    bottom: 0;
    left: 0;
}

.ribbon-top-left span {
    right: -25px;
    top: 30px;
    transform: rotate(-45deg);
}

/* top right*/
.ribbon-top-right {
    top: -10px;
    right: -10px;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
    border-top-color: transparent;
    border-right-color: transparent;
}

.ribbon-top-right::before {
    top: 0;
    left: 0;
}

.ribbon-top-right::after {
    bottom: 0;
    right: 0;
}

.ribbon-top-right span {
    left: -25px;
    top: 30px;
    transform: rotate(45deg);
}

/* bottom left*/
.ribbon-bottom-left {
    bottom: -10px;
    left: -10px;
}

.ribbon-bottom-left::before,
.ribbon-bottom-left::after {
    border-bottom-color: transparent;
    border-left-color: transparent;
}

.ribbon-bottom-left::before {
    bottom: 0;
    right: 0;
}

.ribbon-bottom-left::after {
    top: 0;
    left: 0;
}

.ribbon-bottom-left span {
    right: -25px;
    bottom: 30px;
    transform: rotate(225deg);
}

/* bottom right*/
.ribbon-bottom-right {
    bottom: -10px;
    right: -10px;
}

.ribbon-bottom-right::before,
.ribbon-bottom-right::after {
    border-bottom-color: transparent;
    border-right-color: transparent;
}

.ribbon-bottom-right::before {
    bottom: 0;
    left: 0;
}

.ribbon-bottom-right::after {
    top: 0;
    right: 0;
}

.ribbon-bottom-right span {
    left: -25px;
    bottom: 30px;
    transform: rotate(-225deg);
}

.fondVertTrelli {
    color: #ffffff;
    background-color: #404e40 !important;
    border-bottom: 10px solid #6bdd19;
}

.fondVertTrelliInverse {
    color: #ffffff;
    background-color: #404e40 !important;
    border-top: 10px solid #6bdd19;
}


.fondVertTrelliMince {
    color: #ffffff;
    background-color: #404e40 !important;
    border-bottom: 5px solid #6bdd19;
}

.fondVertTrelliInverseMince {
    color: #ffffff;
    background-color: #404e40 !important;
    border-top: 5px solid #6bdd19;
}

.deepOrangeA400 {
    color: #ffffff;
    background-color: #dd2c00;
    border-bottom: 10px solid #ff6e40;
}

.bgDeepOrangeA400 {
    background-color: #dd2c00;
}

.nav-pills .nav-link.active.cisColor {
    background-color: #404e40;
    color: #64dd17;
}

.cisColorBtn {
    background-color: #404e40;
    color: #64dd17;
}

.cisColorBtn.btn:hover {
    color: #fbff00;
}

.btn-floating,
[class*="btn-outline-"].btn-floating {
    background-color: aliceblue;
}

.megamenu {
    border-right: 2px solid #33691e;
    border-left: 2px solid #33691e;
    border-bottom: 2px solid #33691e;
}

.custCaption {
    /* add this */
    overflow: auto;
    border: none;
    padding: 2px 5px;
    background-color: rgba(31, 141, 231, 0.6);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#0b4fb600, endColorstr=#7F000000) 9;
}

.custCaption2 {
    /* add this */
    overflow: auto;
    border: none;
    padding: 2px 5px;
    background-color: rgba(5, 212, 33, 0.877);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#1fb60b00, endColorstr=#7F000000) 9;
}

.custCaptionBlack {
    /* add this */
    overflow: auto;
    border: none;
    padding: 2px 5px;
    background-color: rgba(46, 2, 2, 0.6);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#24040400, endColorstr=#f50c0c00) 9;
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    left: 15%;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    color: #fff;
    text-align: center;
}

.carousel-custom-caption {
    position: absolute;
    right: 15%;
    top: 0.75rem;
    left: 15%;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    color: #fff;
    text-align: center;
}

.mask.mobile {
    z-index: -1;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #6bdd19;
}

.cardVert {
    background-color: #dcedc8;
}

.fondVertClair {
    background-color: #6bdd19;
}

.fondVertTrelliSimple {
    background-color: #404e40 !important;
}

.textVertTrelliSimple {
    color: #404e40 !important;
}

.rating .active {
    color: #6bdd19 !important;
}

.bg-fluke {
    background: #f3c110 !important;
}

div.justifytext {
    text-align: justify;
}

div.justifytext:after {
    content: "";
    display: inline-block;
    width: 100%;
}

.largeurmax {
    width: 100%;
}

.ytp-button:not([aria-disabled=true]):not([disabled]):not([aria-hidden=true]) {
    cursor: pointer;
}

.ytp-large-play-button {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 40px;
    height: 30px;
    margin-left: -34px;
    margin-top: -24px;
    -webkit-transition: opacity .25s cubic-bezier(0, 0, 0.2, 1);
    transition: opacity .25s cubic-bezier(0, 0, 0.2, 1);
    z-index: 64;
}

.ytp-button:focus,
.ytp-button {
    outline: 0;
}

.ytp-button {
    border: none;
    background-color: transparent;
    padding: 0;
    color: inherit;
    text-align: inherit;
    font-size: 100%;
    font-family: inherit;
    cursor: default;
    line-height: inherit;
}

.fa-li {
    position: relative;
    left: 0;
}


.fondVertBody {
    background-color: #f1f8e9;
}

.menufluke .dropdown-hover:hover>.dropdown-menu {
    display: inline-block;
}

.menufluke .dropdown-hover>.dropdown-toggle:active {
    /*Without this, clicking will make it sticky*/
    pointer-events: none;
}

.fondTransparent {
    text-align: left;
    line-height: normal;
    max-height: 1000px;
    width: auto;
    background: rgba(54, 54, 54, 0.95);
}


.list-group .fondTransparent {
    color: #ffbf00;
}

.list-group-item {
    border: none;
    padding-left: 0px;
}

.fondTransparent.list-group-item-action:focus,
.fondTransparent.list-group-item-action:hover {

    background-color: #0000;
}

.textvert {
    color: #dcedc8;
}

.fondBleu {
    background-color: #f0f8ff;
}

.map-container-7 {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.map-container-7 iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.ml15 {
    font-weight: 400;
    /* font-size: 3.8em;*/
    text-transform: uppercase;
    letter-spacing: 0.15em;
}

.ml15 .word {
    display: inline-block;
    line-height: 1em;
}

.ml10 {
    position: relative;
    font-weight: 600;
    font-size: 2.0em;
}

.ml10 .text-wrapper {
    position: relative;
    display: inline-block;
    padding-top: 0.2em;
    padding-right: 0.05em;
    padding-bottom: 0.1em;
    overflow: hidden;
}

.ml10 .letter {
    display: inline-block;
    line-height: 1em;
    transform-origin: 0 0;
}

.ajs-message.ajs-custom {
    color: #ffffff;
    background-color: #f73606;
    border-color: #000;
}

.ml14 {
    font-weight: 200;
    font-size: 2.2em;
}

.ml14 .text-wrapper {
    position: relative;
    display: inline-block;
    padding-top: 0.1em;
    padding-right: 0.05em;
    padding-bottom: 0.15em;
}

.ml14 .line {
    opacity: 0;
    position: absolute;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: #fff;
    transform-origin: 100% 100%;
    bottom: 0;
}

.ml14 .letter {
    display: inline-block;
    line-height: 1em;
}